<template>
  <div
    :class="{
      'relative ratio-video': data.Use16To9Ratio,
    }"
  >
    <div class="takeover-block">
      <div v-if="isEditMode" class="mb-24">
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="BackgroundMedia"
        >
          Change background media
        </div>
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="BackgroundMediaMobile"
        >
          Change background media mobile
        </div>
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="UseFullWidth"
        >
          Use full width
        </div>
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="MainBodyOnTop"
        >
          Main body on top
        </div>
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="VimeoVideoUrl"
        >
          Vimeo Url
        </div>
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="InvertPlayButtonColor"
        >
          Invert play button color
        </div>
      </div>
      <template v-if="data.LinkUrl">
        <nuxt-link :to="data.LinkUrl">
          <div
            :class="{
              'layout-container':
                !data.UseFullWidth && !data.UsePartialOutsideGrid,
              'partial-layout-container': data.UsePartialOutsideGrid,
            }"
          >
          <div
            class="relative min-h-500 md:min-h-900 take-over-block-bg w-full"
            :class="{
                'flex items-end': data.MainBodyOnTop && !isMobile,
                'aspect-[16/9] max-h-[50vh]': data.Use16To9Ratio,
              }"
            >
            <img
              class="absolute top-0 left-0 object-cover h-full w-full"
              :class="{
                '!object-contain': data.DisableBackgroundCover,
              }"
              :src="backgroundImage"
            />
              <video
                v-if="videoDesktop || videoMobile"
                class="absolute top-0 left-0 h-full object-cover w-full"
                muted
                loop
                autoplay
                playsinline
              >
                <source v-if="!isMobile" :src="videoDesktop" type="video/mp4" />
                <source v-if="isMobile" :src="videoMobile" type="video/mp4" />
              </video>
              <button
                v-if="data.VimeoVideoUrl"
                class="play-button btn btn--round absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 select-none"
                :class="{
                  'btn--light-theme': !data.InvertPlayButtonColor,
                  'btn--dark-theme': data.InvertPlayButtonColor,
                }"
                @click="openModal()"
              >
                {{ $lang('sharedResources', 'startVideoAction') }}
              </button>
              <div
                v-if="data.MainBodyOnTop && !isMobile"
                class="w-full relative z-10"
                :class="{ 'layout-container': data.UseFullWidth }"
              >
                <div
                  class="pb-112 lg:max-w-1/2 lg:pb-208"
                  :class="{
                    'mx-auto': data.CenterMainBody,
                    'pl-56': !data.UseFullWidth,
                  }"
                >
                  <WysiwygWrapper
                    :style-config="{
                      wysiwyg: true,
                      'md:text-center': data.CenterMainBody,
                    }"
                    data-epi-edit="MainBody"
                    :html="data.MainBody"
                  ></WysiwygWrapper>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="(!data.MainBodyOnTop || isMobile) && data.MainBody?.length"
            class="layout-container"
          >
            <div
              class="lg:w-10/12 mx-auto"
              :class="{
                'py-32 md:py-56': data.UseTightMargins,
                'py-56 md:py-80': !data.UseTightMargins,
              }"
            >
              <WysiwygWrapper
                :style-config="{
                  wysiwyg: true,
                  'md:text-center mx-auto': data.CenterMainBody,
                }"
                data-epi-edit="MainBody"
                :html="data.MainBody"
              ></WysiwygWrapper>
            </div>
          </div>
          <div v-if="isEditMode" class="my-24">
            <div
              class="btn btn--dark-theme mr-16 rounded-none px-12"
              data-epi-edit="UseTightMargins"
            >
              Use tight margins
            </div>
            <div
              class="btn btn--dark-theme mr-16 rounded-none px-12"
              data-epi-edit="CenterMainBody"
            >
              Center content
            </div>
          </div>
          <transition name="fade">
            <div
              v-if="showVideoModal"
              class="video-overlay bg-black bg-opacity-80 fixed top-0 left-0 h-full w-full z-modal flex items-center justify-center"
              @click="closeModal"
            >
              <div class="video-container h-0 relative w-full">
                <div
                  class="cursor-pointer select-none fixed md:absolute top-24 right-24 md:right-0 md:top-0 md:-mr-80 md:-mt-80"
                  @click="closeModal"
                >
                  <img
                    src="~/assets/icons/close-white.png"
                    class="h-24 w-24 opacity-80 hover:opacity-100"
                  />
                </div>
                <div
                  class="absolute w-full h-full overflow-hidden top-0 left-0"
                >
                  <iframe
                    :src="VimeoVideoUrl"
                    class="h-full w-full absolute top-0 left-0"
                    width="640"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </transition>
        </nuxt-link>
      </template>
      <template v-else>
        <div
          :class="{
            'layout-container':
              !data.UseFullWidth && !data.UsePartialOutsideGrid,
            'partial-layout-container': data.UsePartialOutsideGrid,
          }"
        >
          <div
            class="relative min-h-500 md:min-h-900 take-over-block-bg w-full"
            :class="{
                'flex items-end': data.MainBodyOnTop && !isMobile,
                'aspect-[16/9] max-h-[50vh]': data.Use16To9Ratio,
              }"
            >
            <img
              class="absolute top-0 left-0 object-cover h-full w-full"
              :class="{
                '!object-contain': data.DisableBackgroundCover,
              }"
              :src="backgroundImage"
            />
            <video
              v-if="videoDesktop || videoMobile"
              class="absolute top-0 left-0 h-full object-cover w-full"
              muted
              loop
              autoplay
              playsinline
            >
              <source v-if="!isMobile" :src="videoDesktop" type="video/mp4" />
              <source v-if="isMobile" :src="videoMobile" type="video/mp4" />
            </video>
            <button
              v-if="data.VimeoVideoUrl"
              class="play-button btn btn--round absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 select-none"
              :class="{
                'btn--light-theme': !data.InvertPlayButtonColor,
                'btn--dark-theme': data.InvertPlayButtonColor,
              }"
              @click="openModal()"
            >
              {{ $lang('sharedResources', 'startVideoAction') }}
            </button>
            <div
              v-if="data.MainBodyOnTop && !isMobile"
              class="w-full relative z-10"
              :class="{ 'layout-container': data.UseFullWidth }"
            >
              <div
                class="pb-112 lg:max-w-1/2 lg:pb-208"
                :class="{
                  'mx-auto': data.CenterMainBody,
                  'pl-56': !data.UseFullWidth,
                }"
              >
                <WysiwygWrapper
                  :style-config="{
                    wysiwyg: true,
                    'md:text-center': data.CenterMainBody,
                  }"
                  data-epi-edit="MainBody"
                  :html="data.MainBody"
                ></WysiwygWrapper>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="(!data.MainBodyOnTop || isMobile) && data.MainBody?.length"
          class="layout-container"
        >
          <div
            class="lg:w-10/12 mx-auto"
            :class="{
              'py-32 md:py-56': data.UseTightMargins,
              'py-56 md:py-80': !data.UseTightMargins,
            }"
          >
            <WysiwygWrapper
              :style-config="{
                wysiwyg: true,
                'md:text-center mx-auto': data.CenterMainBody,
              }"
              data-epi-edit="MainBody"
              :html="data.MainBody"
            ></WysiwygWrapper>
          </div>
        </div>
        <div v-if="isEditMode" class="my-24">
          <div
            class="btn btn--dark-theme mr-16 rounded-none px-12"
            data-epi-edit="UseTightMargins"
          >
            Use tight margins
          </div>
          <div
            class="btn btn--dark-theme mr-16 rounded-none px-12"
            data-epi-edit="CenterMainBody"
          >
            Center content
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="showVideoModal"
            class="video-overlay bg-black bg-opacity-80 fixed top-0 left-0 h-full w-full z-modal flex items-center justify-center"
            @click="closeModal"
          >
            <div class="video-container h-0 relative w-full">
              <div
                class="cursor-pointer select-none fixed md:absolute top-24 right-24 md:right-0 md:top-0 md:-mr-80 md:-mt-80"
                @click="closeModal"
              >
                <img
                  src="~/assets/icons/close-white.png"
                  class="h-24 w-24 opacity-80 hover:opacity-100"
                  height="24"
                  width="24"
                />
              </div>
              <div class="absolute w-full h-full overflow-hidden top-0 left-0">
                <iframe
                  :src="VimeoVideoUrl"
                  class="h-full w-full absolute top-0 left-0"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type TakeOverBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';

const { convertToRelativeUrl } = useStringExtensions();

const showVideoModal = ref(false);

const props = defineProps<{
  block: TakeOverBlockFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const data = computed(() => {
  if(props.block.LinkUrl) {
    props.block.LinkUrl = convertToRelativeUrl(props.block.LinkUrl);
  }
  return props.block as TakeOverBlockFragment;
});

const backgroundImage = computed(() => {
  if (data.value.BackgroundMedia?.Expanded?.Url && !props.isMobile) {
    return `${data.value.BackgroundMedia.Expanded?.Url}`;
  } else if (data.value.BackgroundMediaMobile?.Expanded?.Url && props.isMobile) {
    return `${data.value.BackgroundMediaMobile.Expanded?.Url}`;
  } else if (data.value.BackgroundMedia?.Expanded?.Url && props.isMobile) {
    return `${data.value.BackgroundMedia.Expanded?.Url}`;
  } else {
    return ``;
  }
});

const videoDesktop = computed(() => {
  if (data.value.BackgroundMedia?.Expanded?.Url && !props.isMobile) {
    if (
      data.value.BackgroundMedia.Expanded?.ContentType &&
      data.value.BackgroundMedia.Expanded?.ContentType[0] === 'Video'
    ) {
      return data.value.BackgroundMedia.Expanded.Url;
    }
    return ``;
  } else {
    return ``;
  }
});

const videoMobile = computed(() => {
  if (data.value.BackgroundMediaMobile?.Expanded?.Url && props.isMobile) {
    if (
      data.value.BackgroundMediaMobile.Expanded?.ContentType && data.value.BackgroundMediaMobile.Expanded?.ContentType[0] === 'Video'
    ) {
      return data.value.BackgroundMediaMobile.Expanded.Url;
    }
    return ``;
  } else {
    return ``;
  }
});

const VimeoVideoUrl = computed(() => {
  if (data.value.VimeoVideoUrl) {
    const videoEmbeddedString = data.value.VimeoVideoUrl;
    const videoObj = this.parseVideo(videoEmbeddedString);
    const embedUrl = this.getEmbedUrl(videoObj);
    return embedUrl;
  }
  return '';
});

const openModal = () => {
  showVideoModal.value = true;
  document.body.classList.add('locked');
};

const closeModal = () => {
  showVideoModal.value = false;
  document.body.classList.remove('locked');
};

const parseVideo = (Url: string) => {
  Url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  let type = '';

  if (RegExp.$3.includes('youtu')) {
    type = 'youtube';
  } else if (RegExp.$3.includes('vimeo')) {
    type = 'vimeo';
  }

  return {
    type,
    id: RegExp.$6,
  };
};
</script>
<style>
.ratio-video {
  @screen md {
    padding-bottom: 56.25%;
  }
}

.takeover-block {
  .use-16-9-ratio {
    @apply md:pb-0 md:absolute md:h-full md:w-full md:left-0 md:top-0;
    @screen md {
      height: auto;
      min-height: auto;
    }
  }
  .play-button {
    &:hover {
      transform: translate(-50%, -50%) scale(1.2);
    }
    &:focus {
      outline: none;
    }
  }
  .video-container {
    padding-bottom: 56.25%;

    /* Less padding since width is not 100% */
    @screen md {
      width: 80%;
      padding-bottom: 45%;
    }
  }

  .video-overlay {
    @screen xl {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (min-width: 768px) and (max-height: 900px) {
    .video-overlay {
      @apply overflow-auto items-start py-112;
    }
  }

  @media (min-width: 2400px) and (max-height: 1100px) {
    .video-overlay {
      @apply overflow-auto items-start py-128;
    }
  }

  @media (max-width: 1024px) {
    .wysiwyg {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        span {
          color: #1a1414 !important;
        }
      }

      .btn--light-theme {
        @apply bg-grey800 text-grey200;
        &:hover {
          @apply bg-black text-white;
        }
      }

      .btn--light-ghost-theme {
        @apply border-grey800 bg-transparent text-grey800;

        &:hover {
          @apply bg-black text-white;
        }
      }
    }
  }
}
</style>
